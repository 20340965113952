import useStatefulCookie from "./useStatefulCookie";

const availableThemes = {
	funrize: "Funrize",
	tao: "TaoFortune",
	nlc: "NolimitCoins",
	fortuneWheelz: "FortuneWheelz",
	funzCity: "FunzCity",
	jackpotRabbit: "JackpotRabbit"
};

const useTheme = (applyTheme?: keyof typeof availableThemes) => {
	const theme = useStatefulCookie<keyof typeof availableThemes>("theme", { default: () => "funrize" });

	if (process.client && applyTheme) {
		theme.value = applyTheme;
	}

	return {
		theme,
		availableThemes
	};
};

export default useTheme;
