<script setup lang="ts">
import type { Game } from "@/types";

const props = defineProps<{
	game: Game;
	isFavorite?: boolean;
}>();

const emit = defineEmits<{ (event: "toggleFavorite", game: Game): void }>();

const { theme } = useTheme();
const isGameFavorite = computed(() => props.game?.isFavorite);

const handleToogleToFavorite = () => {
	emit("toggleFavorite", { ...props.game, isFavorite: isGameFavorite.value });
};

const COLORS = {
	funrize: {
		fill: "",
		fillActive: "#fff",
		stroke: "#fff",
		strokeActive: "",
		strokeWidth: 1.7,
		strokeWidthActive: 0
	},
	tao: {
		fill: "#5f5f5f",
		fillActive: "#e3ef18",
		stroke: "",
		strokeActive: "",
		strokeWidth: 0,
		strokeWidthActive: 0
	},
	nlc: {
		fill: "#141722",
		fillActive: "#f31d53",
		stroke: "#fff",
		strokeActive: "",
		strokeWidth: 1.7,
		strokeWidthActive: 0
	},
	fortuneWheelz: {
		fill: "#FFF",
		fillActive: "#F9D01A",
		stroke: "",
		strokeActive: "",
		strokeWidth: 0,
		strokeWidthActive: 0
	},
	funzCity: {
		fill: "#B4B6F0",
		fillActive: "#FF0A0A",
		stroke: "#08134D",
		strokeActive: "",
		strokeWidth: 1.5,
		strokeWidthActive: 0
	},
	jackpotRabbit: {
		fill: "",
		fillActive: "#FF0A0A",
		stroke: "#fff",
		strokeActive: "",
		strokeWidth: 1.7,
		strokeWidthActive: 0
	}
};
</script>

<template>
	<div
		:class="['btn-favorite', { active: isFavorite }]"
		:data-tid="`fav-${game.id || 0}`"
		@click.prevent.stop="handleToogleToFavorite()"
	>
		<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
			<path
				d="M23.25 9.4375C23.25 6.74511 20.9764 4.5625 18.1719 4.5625C16.075 4.5625 14.2749 5.78263 13.5 7.52371C12.7251 5.78263 10.925 4.5625 8.82812 4.5625C6.02355 4.5625 3.75 6.74511 3.75 9.4375C3.75 17.2598 13.5 22.4375 13.5 22.4375C13.5 22.4375 23.25 17.2598 23.25 9.4375Z"
				:fill="isFavorite ? COLORS[theme].fillActive : COLORS[theme].fill"
				:stroke="isFavorite ? COLORS[theme].strokeActive : COLORS[theme].stroke"
				:stroke-width="isFavorite ? COLORS[theme].strokeWidthActive : COLORS[theme].strokeWidth"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</div>
</template>

<style scoped lang="scss">
$transition: all 200ms ease-out;

.btn-favorite {
	position: absolute;
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: $transition;
}
</style>
